import { useScript } from '@bluheadless/ui-logic/src/hooks/use-script/useScript'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useStore } from '@bluheadless/ui-logic/src/providers/store'
import { useEffect } from 'react'

const logger = (...args) => console.debug('%c[StyleGenie]', 'color: #fff;background-color:#000E8A', ...args)

const StyleGenie = ({ configs: { domain = '', debug = false, element = 'body', ...configs } } = {}) => {
	const { projectSpecific: { styleGenie: { scriptPath, cssPath, clientKey } = {} } = {} } = useConfig()
	const { code: currentStoreCode, locale } = useStore()

	const scriptStatus = useScript({ src: domain && `${domain}${scriptPath}` })

	if (debug) logger({ scriptStatus })

	useEffect(() => {
		const CSS_URL = `${domain}${cssPath}`
		if (document.querySelector(`link[href="${CSS_URL}"]`)) {
			if (debug) logger('CSS already loaded!')
		} else {
			if (debug) logger('CSS loading...')
			const link = document.createElement('link')
			link.href = CSS_URL
			link.rel = 'stylesheet'
			document.head.appendChild(link)

			if (debug) logger('CSS loaded!')

			return () => {
				if (debug) logger('removing CSS...')
				document.head.removeChild(link)
			}
		}
	}, [domain, cssPath, debug])

	useEffect(() => {
		if (scriptStatus === 'ready') {
			if (debug) logger('initiating StyleGenie with:', { clientKey, element, ...configs })
			// https://docs.4gift.com/style-genie/integration/web-component-solution/method-and-events#init
			window.StyleGenie.init({ clientKey, element, ...configs })

			// https://docs.4gift.com/style-genie/integration/web-component-solution/method-and-events#createentrypoint
			const params = [locale.split('-')[0], currentStoreCode, {}]

			if (debug) logger('creating entry point with params:', params)

			if (document.getElementById('style-genie-entry-point')) {
				if (debug) logger('removing existing entry point before creating a new one...')
				document.getElementById('style-genie-entry-point').remove()
			}
			window.StyleGenie.createEntryPoint(params)

			return () => {
				if (debug) logger('destroying StyleGenie...')
				if (document.getElementById('style-genie-entry-point')) {
					document.getElementById('style-genie-entry-point').remove()
				}
			}
		}
	}, [clientKey, configs, currentStoreCode, debug, element, locale, scriptStatus])

	return null
}

export default StyleGenie
